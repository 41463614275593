<template>
    <div class="row">
        <div class="col-12 mb-1">
            <label class="form-label">Araç Tipi Kodu</label>
            <input type="text" class="form-control" v-model="value.Code" placeholder="Araç Tipi Kodu" />
            <label class="form-label">Araç Tipi Adı</label>
            <input type="text" class="form-control" v-model="value.Name" placeholder="Araç Tipi Adı" />
        </div>
        
    </div>
</template>

<script>
export default {
    props:{
        value:Object
    },
    data(){
        return{
           
        }
    },
}
</script>

<style>

</style>